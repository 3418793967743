<template>
  <div class="field-detail">
    <van-nav-bar
      :title="position"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="field-detail-wrap">
      <!-- 天气部分 -->
      <div class="live-weather">
        <my-live-weather :lngLat="lngLat" />
      </div>
      <!-- 天气预报和土壤监测 -->
      <div class="forcast-and-soilTest">
        <div class="forcast-and-soilTest-warp">
          <van-tabs type="card" color="#001F90">
            <van-tab title="气象数据">
              <div class="forcast-warp">
                <my-forecast-weather :lat="lngLat.lat" :lng="lngLat.lng" />
              </div>
            </van-tab>
            <van-tab title="土壤检测">
              <div class="survey-wrap">
                <my-soil-detect :landId="id" />
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
      <!-- 养殖区域 -->
      <div class="field-info">
        <div class="field-img">
          <img v-if="detailsData.imageUrl" :src="detailsData.imageUrl" alt="" />
          <div v-else style="width: 100%; height: 100%; background: #ddd"></div>
        </div>
        <div class="field-info-detail">
          <div class="detail-item">
            <div class="name">{{ detailsData && detailsData.name }}</div>
            <div class="contact">{{ detailsData && detailsData.linkman }}</div>
          </div>
          <div class="detail-item">
            <div class="location">
              {{
                `${detailsData && detailsData.province_name}-${
                  detailsData && detailsData.city_name
                }-${detailsData && detailsData.county_name}`
              }}
            </div>
            <div class="area-num">{{ detailsData && detailsData.area }}亩</div>
          </div>
        </div>
      </div>
      <!-- 养殖区域 -->
      <div class="crop-list">
        <div class="area-total">养殖畜禽：{{ cropNum }}种畜禽</div>
        <div
          class="crop-item"
          v-for="(item, index) in breedDetail"
          :key="index"
        >
          <div class="crop-img">
            <img
              v-if="item.imageUrl"
              :src="item.imageUrl"
              style="width: 100%; height: 100%; background: #ddd"
            />
            <div
              v-else
              style="width: 100%; height: 100%; background: #ddd"
            ></div>
          </div>
          <div class="crop-info">
            <div class="crop-total">
              {{ index }} {{ item && item.varietyList.length }}个品种
            </div>
            <div class="crop-detail">
              <span v-for="(crop, cropIndex) in item.varietyList" :key="cropIndex"
                >{{ crop.animal_variety_name }}({{
                  crop.animalVarietyCnt
                }}头)</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 养殖区域 -->
    </div>
  </div>
</template>

<script>
import { NavBar, Tab, Tabs } from "vant"
import MyLiveWeather from "./liveWeather.vue"
import MyForecastWeather from "@/components/home/MaxAndMinTemp.vue"
import MySoilDetect from "./cropDetail/soilDetect.vue"
import {
  getDetailsInit,
  EnclosureDetailsInit
} from "../../service/Enclosure/Enclosure"
export default {
  name: "",
  props: ["id"],
  components: {
    "van-nav-bar": NavBar,
    "van-tabs": Tabs,
    "van-tab": Tab,
    "my-live-weather": MyLiveWeather,
    "my-forecast-weather": MyForecastWeather,
    "my-soil-detect": MySoilDetect
  },
  data() {
    return {
      detailsData: {},
      lngLat: {},
      breedDetail: {}
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    amapGeocoder(position) {
      return new Promise((resolve, reject) => {
        AMap.plugin("AMap.Geocoder", function () {
          var geocoder = new AMap.Geocoder()
          geocoder.getLocation(position, function (status, result) {
            if (status === "complete" && result.geocodes.length) {
              var lngLat = result.geocodes[0].location
              resolve(lngLat)
            } else {
              reject(new Error("根据地址查询位置失败"))
            }
          })
        })
      })
    },
    init() {
      EnclosureDetailsInit(this.id).then((res) => {
        console.log(res);
        this.breedDetail = res.data
      })
    }
  },
  mounted() {
    this.init()
    getDetailsInit(this.id).then((res) => {
      this.detailsData = res.data
      const position = `${res.data.province_name}${res.data.city_name}${res.data.county_name}`
      this.amapGeocoder(position).then((res) => {
        this.lngLat = {
          lat: res.lat,
          lng: res.lng
        }
      })
    })
  },
  watch: {},
  computed: {
    position: function () {
      return this.detailsData.city_name && this.detailsData.county_name
        ? `${this.detailsData.city_name}-${this.detailsData.county_name}`
        : ""
    },
    cropNum() {
      return Object.keys(this.breedDetail).length
    }
  },
  filters: {}
}
</script>

<style scoped lang='scss'>
.field-detail {
  color: #333;
  box-sizing: border-box;
  font-size: 14px;
  .field-detail-wrap {
    margin: 0 10px 20px;
    .forcast-and-soilTest {
      margin-top: 14px;
      .forecast-wrap {
        margin-top: 20px;
      }
      .survey-wrap {
        margin-top: 20px;
        font-size: 22px;
        height: 200px;
        text-align: center;
      }
    }
    .field-info {
      margin-top: 20px;
      display: flex;
      height: 60px;
      background: #edfafd;
      .field-img {
        flex: 0 0 80px;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .field-info-detail {
        display: flex;
        margin-left: 14px;
        flex-direction: column;
        justify-content: space-around;
        .detail-item {
          display: flex;
          align-items: center;
          .name {
            font-weight: 600;
          }
          .contact {
            flex: 1 0 auto;
            margin-left: 26px;
          }
          .detail-item-carbon {
            margin-left: 32px;
            background: #d0e17d;
            padding: 0 8px;
          }
          div + div {
            margin-left: 20px;
          }
        }
      }
    }

    .crop-list {
      margin-top: 20px;
      .area-total {
        margin-bottom: 20px;
      }
      .crop-item {
        height: 60px;
        display: flex;
        padding: 6px;
        border: 1px solid #ddd;
        & + .crop-item {
          margin-top: 20px;
        }
        .crop-img {
          flex: 0 0 80px;
        }
        .crop-info {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          justify-content: space-around;
          .crop-detail {
            display: flex;
          }
          .crop-total {
            display: flex;
            .crop-total-carbon {
              background: #d0e17d;
              padding: 0 8px;
              margin-left: 40px;
            }
          }
          .crop-detail {
            span + span {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.field-detail .forcast-and-soilTest .van-tabs__nav--card {
  margin: 0 auto !important;
  width: 40%;
}

.field-detail .field-info .van-tabs__nav--card {
  margin: 0 !important;
  width: 60%;
}
</style>
